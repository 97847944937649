"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractorInventoryItem = void 0;
const merge_1 = require("@walls/merge");
/**
 * Create the contractor inventory item
 */
class ContractorInventoryItem {
    constructor(data) {
        /**
         * The main product identifier
         */
        this.product_id = "";
        /**
         * The item category
         *
         * @example `trims | boots | panel`
         */
        this.category = "";
        /**
         * The publicly known name for the item
         */
        this.name = "";
        /**
         * A descriptive text for the item. This will be used on the website to describe the item.
         *
         * This is also used when printing quotes for the panels selected
         */
        this.description = "";
        /**
         * If this is a child item then set the parent id
         */
        this.parentId = null;
        /**
         * The name of the parent product
         */
        this.parentName = "";
        /**
         * List of items that are a child of this... This is used when converting from one price to another
         */
        this.childItems = [];
        /**
         * A key-value pairing for the material to product relation ships.
         *
         * Use this mapping to check which product to map to.
         *
         * The key is the priceCategory id, and the value is the product id.
         */
        this.materialMapping = null;
        /**
         * The list of potential parent ids for the given product
         */
        this.materialParents = [];
        /**
         * The price category this item is part of
         */
        this.priceCategory = "";
        /**
         * Tells if the item in question is a panel or regular item.
         */
        this.isPanel = false;
        /**
         * Tells if the product is a trim
         */
        this.isTrim = false;
        /**
         * The panel width.
         *
         * This value is used to calculate quantities between different panel types
         */
        this.panelWidth = 0;
        /**
         * The weight of the product.
         *
         * If this is a panel then the weight of a linear foot
         */
        this.weight = 0;
        /**
         * The price that we pay in CAD
         */
        this.buyingPrice = 0;
        /**
         * Our markup percentage
         */
        this.markupPercentage = 0;
        /**
         * Our retail selling price of the item
         */
        this.sellingPrice = 0;
        /**
         * The price that the contractor is paying for this item
         */
        this.contractorPrice = 0;
        /**
         * Our current qty on hand...
         *
         * This value might not be accurate and should be updated daily
         *
         * This value should be pulled from items_qty_current table while fetching the item
         */
        this.quantityOnHand = 0;
        /**
         * A list of images for the product
         */
        this.productImages = [];
        /**
         * The svg file name for this product.
         * The svg file is a 2d vector of the product as a visual.
         * This will be printed on quotes for panels
         */
        this.productSVG = "";
        /**
         * The url for the obj file that might be stored for this item as a visual
         */
        this.productOBJ = "";
        /**
         * The date that the item was created
         */
        this.dateCreated = Math.floor(Date.now() / 1000);
        /**
         * Time of last edit
         */
        this.lastedit = 0;
        /**
         * Tells if the item is deleted or not
         */
        this.deleted = false;
        if (data) {
            this.update(data);
        }
    }
    /**
     * Update the current inventory Item data
     *
     * @param data
     */
    update(data) {
        // merge the incoming object into this class
        Object.assign(this, data);
    }
    /**
     * Get thr product image
     *
     */
    getProductImage() {
        if (this.productSVG != "") {
            return this.productSVG;
        }
        return this.productImages[0] || "";
    }
    /**
     * Clones the current contractor item object
     */
    clone() {
        return new ContractorInventoryItem((0, merge_1.clone)(this));
    }
}
exports.ContractorInventoryItem = ContractorInventoryItem;
