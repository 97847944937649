// @ts-check

/**
 * Check if the incoming error is a timeout error.
 *
 * If it is, log it as a timeout error.
 *
 * @param {import("custom-error/error").CustomError} error
 */
export function checkTimeoutError(error) {
    try {
        if (
            error &&
            error.name &&
            (error.name === "Timeout Error" || error.name === "Time Out")
        ) {
            /**
             * @type {{api: string, type: "api" | "worker", internalId: string | null}}
             */
            const data = {
                api: error.api || "unknown",
                type: "api",
                internalId: error.internalId || null,
            };

            if (error.workerApi) {
                data.type = "worker";
                data.api = error.workerApi;
            }

            if (!error.counted) {
                // loaded inline to avoid circular dependencies
                const { timeoutCounter } = require("metrics/counters.js");
                timeoutCounter(data.api, data.type);
            }
            error.counted = true;

            return data;
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Timeout Tracking Error", err);

        const Sentry = require("sentry");
        Sentry.captureException(err);
    }
    return null;
}
