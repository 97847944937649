"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createContactSettings = createContactSettings;
const merge_1 = require("@walls/merge");
/**
 * Create the settings values
 *
 * @param data
 * @returns
 */
function createContactSettings(data) {
    const defaultSettings = {
        defaultCurrency: "CAD",
        deliveryPrice: 0,
        calculateDeliveryPrice: true,
        depositRequired: 50,
        onlineOrderProfile: false,
        onlineOrderBeta: false,
        onlineOrderUsers: [],
        discountId: "default",
        vendorShortCode: "",
        defaultCoilCoreDiameter: 20.5,
    };
    // setup the online order users details
    if (data.onlineOrderUsers) {
        defaultSettings.onlineOrderUsers = data.onlineOrderUsers.map(data => {
            const defaultUser = {
                userId: "",
                displayName: "",
            };
            return (0, merge_1.assign)(defaultUser, data);
        });
        delete data.onlineOrderUsers;
    }
    return (0, merge_1.assign)(defaultSettings, data);
}
